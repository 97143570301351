import { ref } from 'vue'

export interface RightNow {
  BUILD: string
  Client: {
    ConditionalChatLink(): void
    Controller: {
      addComponent(component: object, url: string): void
    }
  }
}

export interface MyChat {
  attrs: object
  data: object
  chatLinkClicked(): void
}

declare const myChat: MyChat
declare const RightNow: RightNow
const isRightnowLoaded = ref(false)

export default function () {
  function configMyChat() {
    useHead({
      script: [
        {
          hid: 'rightnow',
          async: true,
          src: 'https://tafensw.widget.custhelp.com/euf/rightnow/RightNow.Client.js',
          onload: () => {
            if (!isRightnowLoaded.value) {
              addMyChatWidget()
              isRightnowLoaded.value = true
            }
          }
        }
      ]
    })
  }

  function openMyChat() {
    // Oracle RightNow method to open chat
    if (typeof myChat !== 'undefined' && myChat !== null) {
      const { gtag } = useGtag()
      myChat.chatLinkClicked()
      gtag('event', 'chatClick')
    }
  }

  function addMyChatWidget() {
    if (typeof RightNow !== 'undefined' && RightNow !== null) {
      // Live chat widget
      RightNow.Client.Controller.addComponent(
        {
          container_element_id: 'myChatLinkContainer',
          custom_fields: '{ "67": "E2E-Beta" }',
          info_element_id: 'myChatLinkInfo',
          link_element_id: 'myChatLink',
          instance_id: 'myChat',
          module: 'ConditionalChatLink',
          type: 7
        },
        'https://tafensw.widget.custhelp.com/ci/ws/get'
      )
    }
  }

  return { configMyChat, openMyChat }
}
